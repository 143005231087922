<template>
  <div>
    <Header ref="header"/>

    <div class="content article-content">
      <div style="height: 20px"></div>
      <div class="w w1200 clearfix">
        <div class="article-side">
          <dl class="article-menu">
            <dt class="am-t">
              <a href="javascript:void(0);">文章分类列表</a>
            </dt>
            <dd class="am-c">
              <div class="menu-item" v-for="(item, index) in list" :key="index">
                <div class="item-hd">
                  <router-link :to="{path:'/article/page',query:{categoryId:item.id}}" tag="a">
                    {{item.name}}
                  </router-link>
                  <i class="iconfont icon-down" v-if="item.children"></i>
                </div>
                <ul class="item-bd" v-if="item.children">
                  <li v-for="(innerItem, innerIndex) in item.children" :key="innerIndex">
                    <router-link :to="{path:'/article/page',query:{categoryId:innerItem.id}}" tag="a">
                      <span>{{innerItem.name}}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </dd>
          </dl>
        </div>
        <div class="article-main">
          <router-view/>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {getTree} from '@/api/cms/category'

export default {
  name: "Index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    getTree({}).then(res => {
      this.list = res.data.list
    })
  }
}
</script>

<style scoped>
.w{
  padding-bottom: 30px;
}
.footer-new{
  margin-top: 0;
}
</style>
